/* This file simply imports all the relevant tailwind parts */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Gross hack below to overwrite tailwind reset breaking amplify's buttons on login screen */

.amplify-button--primary {
  background-color: rgb(4, 125, 149) !important;
}

.amplify-button--primary:hover {
  background-color: rgb(0, 85, 102) !important;
}